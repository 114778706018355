/** @jsx jsx */
import { useEffect, useState, useRef } from "react"
import { jsx, Flex, Checkbox } from "theme-ui"
import { graphql } from "gatsby"
import { useTranslation } from "gatsby-plugin-react-i18next"

import Layout from "../components/layout"
import Columns from "../components/columns"
import Tab from "../components/tab"
import { CharacterList } from "../components/characters"
import Result, { Random } from "../components/@team-builder-result"
import UseLocalStorage from "../hooks/use-local-storage"

import Svg from "../components/svg"

const Page = () => {
	const {t} = useTranslation()
		, storage = useRef(new UseLocalStorage(`team-builder`))
		, data = useRef(storage.current.get(`data`))
		, init = useRef({
			owned: data.current ? data.current.owned : ["Lumine", "Amber", "Kaeya", "Lisa", "Barbara", "Noelle"],
			wl8: data.current ? data.current.wl8 : false,
		})
		, [owned, setOwned] = useState(init.current.owned)
		, [wl8, setWl8] = useState(init.current.wl8)
		, [num, setNum] = useState(Date.now())
	useEffect(()=>{
		storage.current.set(`data`, {owned: owned, wl8: wl8,})
	},[owned,wl8])
	return (<Layout title={t(`Random Team Generator`)}>
		<Columns>
			<div>
				<Random owned={owned} wl8={true} num={num} t={t} />
				<div sx={{textAlign: `center`,}}>
					<div
						sx={{
							width: `100px`,
							mx: `auto`,
							cursor: `pointer`,
						}}
						onClick={()=>setNum(Date.now())}
					>
						<Svg i="dice" />
					</div>
				</div>
			</div>
			<div>
				<div>{t(`Owned Characters`)}</div>
				<CharacterList
					name="owned-characters"
					init={init.current.owned}
					listName="teamBuilder"
					t={t}
					onChange={setOwned}
				/>
			</div>
		</Columns>
	</Layout>)
}
export default Page

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {ns: {in: ["common","team-builder"]}, language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
